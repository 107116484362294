import React, { Suspense, useContext, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { AuthContext } from "./context/AuthContext";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
// import { ToastContainer } from "react-toastify";

import authService from "./services/auth";
import { LoadingProvider } from "./context/LoadingContext";

const SignUp = React.lazy(() => import("./pages/SignUp/SignUp"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const Home = React.lazy(() => import("./pages/Home/Home"));
const Plans = React.lazy(() => import("./pages/Plans/Plans"));
const Workouts = React.lazy(() => import("./pages/Workouts/Workouts"));
const History = React.lazy(() => import("./pages/History/History"));
const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const NavBar = React.lazy(() => import("./components/NavBar"));

const App = () => {
  const { user, setUser } = useContext(AuthContext);
  let history = useHistory();

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const { _id, name, lastname, email } = await authService.GetCurrentUser();

      setUser({
        id: _id,
        name: name,
        lastname: lastname,
        email: email,
        isLoggedIn: true,
        isLoading: false,
        token: localStorage.getItem("token"),
      });
    } catch (error) {
      history.push("/login");
    }
  };

  return (
    <div className="flex flex-col">
      <Suspense fallback={null}>
        <LoadingProvider>
          <Switch>
            <PublicRoute
              restricted={true}
              exact
              path="/sign-up"
              component={SignUp}
            />
            <PublicRoute
              restricted={true}
              exact
              path="/login"
              component={Login}
            />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/plans" component={Plans} />
            <PrivateRoute exact path="/history" component={History} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/plans/:planId" component={Workouts} />
            <Route path="*">
              {user.isLoggedIn ? (
                <Redirect to="/home" />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
          </Switch>
          {user.isLoggedIn && <NavBar />}
          {/* <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          style={{ marginBottom: "3rem"}}
        /> */}
        </LoadingProvider>
      </Suspense>
    </div>
  );
};

export default App;
