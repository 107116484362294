export const getBaseUri = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000/api";
  } else {
    return "https://tracksercise.herokuapp.com/api";
  }
};

export const getHeaders = () => {
  return {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
};
