import axios from "axios";
import { getBaseUri, getHeaders } from "./api";

const baseUri = `${getBaseUri()}/auth`;
const headers = getHeaders();

const authService = {
  GetCurrentUser: async () => {
    const response = await axios.get(`${baseUri}/current-user`, headers);
    return response.data;
  },
  Login: async (userInfo) => {
    const response = await axios.post(`${baseUri}/login`, userInfo, headers);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.user));
    return response.data;
  },

  SignUp: async (userInfo) => {
    const response = await axios.post(`${baseUri}/register`, userInfo, headers);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.user));
    return response.data;
  },
};

export default authService;
