import React, { useContext } from "react";
import { Route, Redirect } from "react-router";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        user.isLoggedIn || user.isLoading ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
