import React, { useState, createContext } from "react";

const AuthContext = createContext(null);

const AuthProvider = (props) => {
  const [user, setUser] = useState({
    id: "",
    name: "",
    lastName: "",
    email: "",
    isLoggedIn: false,
    isLoading: true,
    token: null,
  });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
