import { createContext, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

export const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <LoadingOverlay
        active={false}
        styles={{
          wrapper: {
            height: "100%",
            width: "100%",
          },
        }}
        fadeSpeed={0}
        spinner
      >
        {children}
      </LoadingOverlay>
    </LoadingContext.Provider>
  );
};
